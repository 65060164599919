import React from 'react';
import tw from 'twin.macro';

function IconSpinnerBase(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zM4.8 12a7.2 7.2 0 1014.4 0 7.2 7.2 0 00-14.4 0z"
        fill="currentColor"
      />
      <path
        d="M2.745 9.45C1.467 9.096.696 7.76 1.295 6.576a12 12 0 0120.876-.945c.703 1.123.056 2.525-1.184 2.992-1.241.466-2.6-.202-3.447-1.222A7.198 7.198 0 007.51 6.372a7.2 7.2 0 00-1.442 1.55c-.751 1.091-2.044 1.88-3.322 1.527z"
        fill="white"
      />
    </svg>
  );
}

const IconSpinner = tw(IconSpinnerBase)`animate-spin`;

export default IconSpinner;
