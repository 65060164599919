import { TinyColor } from '@ctrl/tinycolor';
import { Interpolation, Theme } from '@emotion/react';
import React, { forwardRef, HTMLProps } from 'react';
import tw from 'twin.macro';

import { contrastingColors } from '@chartsy/shared/utils';

import IconSpinner from './Icon/Spinner';
import { FOCUS_RING_CSS } from './Styled';

type ButtonHues = 'danger' | 'gray' | 'pink' | 'purple' | 'blue' | 'vibrant';
type ButtonShapes = 'pill' | 'square' | 'circle';
type ButtonSizes = 'base' | 'large';
type ButtonVariants = 'solid' | 'soft' | 'transparent';

// Darken colors without background
const DARKEN_HOVER_TW = `can-hover:hover:boxShadow[inset 0 0 0 99999px rgba(0,0,0,0.2)]`;
export const DISABLED_TW = 'opacity-50 pointer-events-none';

const BUTTON_CSS: {
  [hueKey in ButtonHues]: {
    [variantKey in ButtonVariants]: Interpolation<Theme>;
  };
} = {
  danger: {
    solid: tw`bg-danger-800 text-white ${DARKEN_HOVER_TW}`,
    soft: tw`bg-danger-200 text-danger-800 ${DARKEN_HOVER_TW}`,
    transparent: tw`text-danger-800 can-hover:hover:(text-white bg-danger-800)`,
  },
  gray: {
    solid: tw`bg-gray-600 text-white can-hover:hover:bg-gray-700`,
    soft: tw`bg-gray-200 text-gray-600 can-hover:hover:bg-gray-300`,
    transparent: tw`text-gray-600 can-hover:hover:bg-gray-300`,
  },
  blue: {
    solid: tw`bg-blue-600 text-white can-hover:hover:bg-blue-700`,
    soft: tw`bg-blue-200 text-blue-600 can-hover:hover:bg-blue-300`,
    transparent: tw`text-blue-600 can-hover:hover:bg-blue-300`,
  },
  purple: {
    solid: tw`bg-purple-600 text-white can-hover:hover:bg-purple-700`,
    soft: tw`bg-purple-200 text-purple-600 can-hover:hover:bg-purple-300`,
    transparent: tw`text-purple-600 can-hover:hover:bg-purple-300`,
  },
  pink: {
    solid: tw`bg-pink-600 text-white can-hover:hover:bg-pink-700`,
    soft: tw`bg-pink-200 text-pink-600 can-hover:hover:bg-pink-300`,
    transparent: tw`text-pink-600 can-hover:hover:bg-pink-300`,
  },
  vibrant: {
    solid: tw`text-white bg-gradient-to-br from-pink-400 via-purple-500 to-blue-400 ${DARKEN_HOVER_TW}`,
    soft: tw``, // unused
    transparent: tw``, // unused
  },
};

type Color = TinyColor | string;

interface ButtonPropsBase extends HTMLProps<HTMLButtonElement> {
  isLoading?: boolean;
  type?: 'button' | 'submit';
}

export type ButtonCssProps = { shape?: ButtonShapes } & (
  | {
      color?: Color;
      hue: ButtonHues | 'custom';
      size?: ButtonSizes;
      variant?: ButtonVariants;
    }
  | {
      color: Color;
      hue: 'custom';
      shape?: ButtonShapes;
      size?: ButtonSizes;
      variant: 'solid';
    }
);

export type ButtonProps = Omit<ButtonPropsBase, 'color'> & ButtonCssProps;

export function cssForButtonProps({
  color,
  hue,
  shape = 'pill',
  size = 'base',
  variant = 'solid',
}: ButtonCssProps) {
  let buttonCss: Interpolation<Theme>;

  if (hue === 'custom') {
    const tinyColor = new TinyColor(color);
    const { backgroundColor, foregroundColor } = contrastingColors(tinyColor);
    buttonCss = {
      backgroundColor: backgroundColor.toHexString(),
      color: foregroundColor.toHexString(),
      '&:hover': {
        backgroundColor: backgroundColor.darken(10).toHexString(),
      },
    };
  } else {
    buttonCss = BUTTON_CSS[hue][variant];
  }

  return [
    FOCUS_RING_CSS,
    size === 'large' ? tw`text-xl sm:text-2xl` : tw`sm:text-lg`,
    tw`font-bold disabled:(${DISABLED_TW}) focus:(ring-offset-2 ring-2)`,
    shape !== 'square' && tw`rounded-full `,
    shape === 'pill'
      ? size === 'large'
        ? tw`py-3 px-6 sm:(text-2xl py-4 px-8)`
        : tw`py-2 px-4`
      : tw`flex items-center p-2`,
    buttonCss,
  ];
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      color,
      hue,
      isLoading,
      shape,
      size,
      type = 'button',
      variant,
      ...rest
    },
    ref,
  ) => (
    <button
      css={[
        isLoading && tw`flex justify-center cursor-wait`,
        cssForButtonProps({
          color,
          hue,
          shape,
          size,
          variant,
        }),
      ]}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...rest}
    >
      {isLoading ? (
        <IconSpinner
          // Solid variants have darker backgrounds which means the spinner
          // needs to be darker to be seen
          css={(!variant || variant === 'solid') && tw`color[rgba(0,0,0,0.5)]`}
          tw="h-5 w-5 sm:(h-7 w-7)"
        />
      ) : (
        children
      )}
    </button>
  ),
);

export default Button;
